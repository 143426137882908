import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Nft from "./components/Nft";
import ResetPassword from "./components/ResetPassword";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/tm-login" element={<Login />}>
          </Route>
           <Route path="/dashboard"  element={<><Navbar /> <Nft/></>}>
          </Route>
           <Route path="/reset-password"  element={<><Navbar /> <ResetPassword/></>}>
          </Route>
          <Route path="*" element={<Home />} ></Route>
        </Routes>
      </BrowserRouter>
  );
}


export default App;