import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../constants/config';
const backend_server = Constants.BACKEND_SERVER;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const history = useNavigate();

  useEffect(() => {
    document.body.style.backgroundImage = "none";
  }, []);

  const Auth = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${backend_server}/login`, {
        email: email,
        password: password
      });
      const token = response.data.accessToken;
      localStorage.setItem("token", token);
      history("/dashboard");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  }

  return (
          <div className="container">
            <div className="row justify-content-center">
              <div className="form-group col-md-4 col-md-offset-4 align-center ">
                <form onSubmit={Auth} className="box">
                  <p className="has-text-centered">{msg}</p>
                  <div className="form-group">
                    <label className="">Email or Username</label>
                    <div className="controls">
                      <input type="text" className="form-control" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="">Password</label>
                    <div className="controls">
                      <input type="password" className="form-control" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-success">Login</button>
                  </div>
                </form>
                </div>
                </div>
          </div>
  )
}

export default Login