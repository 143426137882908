import { Toaster } from 'react-hot-toast';
import birds from '../img/birds.gif';
import dino_bird1 from '../img/dino_bird1.gif';
import header_layer_bg from '../img/header_layer_bg.png';
import header_layer_top_bottom_fade from '../img/header_layer_top_bottom_fade.png';
import plant from '../img/plant.png';
import dino_egg from '../img/dino_egg.gif';
import footer_bg from '../img/footer_bg.png';

import discord_logo from '../img/discord_logo.png';
import twitter_logo from '../img/twitter_logo.png';

import Faq from "./Faq";
import Mint from "./Mint";

const Home = () => {
  return (
      <div> 
          
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
                background: '#363636',
                color: '#fff',
                maxWidth: 600,
                marginTop:26
              },
              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                  primary: 'green',
                  secondary: 'black',
                },
              },
            }}
        />

        <header id="navbar-container" >
            <nav className="navbar navbar-default navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>

                    <div className="collapse navbar-collapse navbar-right" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-left">
                            <li className="active"><a href="#header">Home <span className="sr-only">(current)</span></a></li>
                            <li><a href="#mint" rel="noreferrer">Mint</a></li>
                            <li><a href="#about" rel="noreferrer">About</a></li>
                            <li><a href="#roadmap" rel="noreferrer">Roadmap</a></li>
                            <li><a href="#faq" rel="noreferrer">FAQs</a></li>
                            <li><a href="#team" rel="noreferrer">Team</a></li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <a href="https://twitter.com/ExploringDino" target="_blank" rel="noreferrer">
                                    <img src={twitter_logo} alt="img"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://discord.gg/tUP7rPuvag" target="_blank" rel="noreferrer">
                                    <img src={discord_logo} alt="img"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

        <img className="fixed-bg" src={header_layer_bg} alt="img"/>

        <section id="header" className="header-container">
            <div className="header-body">
                <h1 className="logo-text">Exploring <span>Dino's</span></h1>
            </div>
            <img className="top-bg" src={header_layer_top_bottom_fade} alt="img"/>
            <img className="birds-bg" src={birds} alt="img"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 text-center left">
                        <a href="#about" rel="noreferrer">
                            <div className="scroll-ani">
                                <div className="wheel"></div>
                            </div>
                            <p>Scroll Down</p>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <Mint />

        <section id="about" className="about-container theme-color">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6 text-left left">
                        <h2 className="title">About Us</h2>
                        <p>Who are we? We are a team existing of two enthusiastic guys that translated our passion for exploring into an unique NFT.</p>
                        <p>
                        Exploring Dino’s are tiny and clever creatures that create ingenious ways to wander the unknown. You can see them crawling, digging, swimming and slithering in almost every part of this planet and throughout the universe.
                        </p>
                    </div>
                    <div className="col-sm-6 text-center right">
                        <img className="plant" src={plant} alt="img"/>
                    </div>
                </div>
            </div>
        </section>

        <section id="roadmap" className="roadmap-container">
            <h2 className="title text-center">Roadmap</h2>
            <img className="dino_bird1" src={dino_bird1} alt="img"/>
            <div className="horizontal-scroll-items-top"></div>
            <div className="horizontal-scroll-items">
                <div className="inner">
                    <div className="box">
                        <h1>10%</h1>
                        <p>Launching Website, Discord, Twitter</p>
                    </div>
                    <div className="box">
                        <h1>20%</h1>
                        <p>Launching Pre-Sale (0.03 eth)</p>
                    </div>
                    <div className="box">
                        <h1>30%</h1>
                        <p>Launching Public Sale (0.0555 eth)</p>
                    </div>
                    <div className="box">
                        <h1>40%</h1>
                        <p>Revealing NFT's Collection V1</p>
                    </div>  
                    <div className="box">
                        <h1>50%</h1>
                        <p>Community Giveaway</p>
                    </div>
                    <div className="box">
                        <h1>60%</h1>
                        <p>Reveal exclusive club access for ED Holders including interactive game environment and a revamp of the entire website</p>
                    </div>
                    <div className="box">
                        <h1>70%</h1>
                        <p>Revealing donation causes backed by the Exploring Dino Team to various institutions</p>
                    </div>
                    <div className="box">
                        <h1>80%</h1>
                        <p>Launching Exploring Dino's Merch (hats, t-shirts and sweaters)</p>
                    </div>
                    <div className="box">
                        <h1>90%</h1>
                        <p>Revealing Roadmap V2</p>
                    </div>
                    <div className="box">
                        <h1>100%</h1>
                        <p>Launching Exploring Dino's V2</p>
                    </div>
                </div>
            </div>
        </section>

        <Faq />

        <section id="team" className="team-container theme-color">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 text-center right">
                        <h2 className="title text-center">Our Team</h2>
                    </div>
                    <div className="col-sm-12 team-items-container text-center right">
                        <div className="team-item">
                            <img className="dino-egg" src={dino_egg} alt="img"/>

                            <h2>DoctorPeacock</h2>
                            <p>Co-Founder of Exploring Dino’s</p>
                            <a href="https://twitter.com/doctorpeacock" target="_blank" rel="noreferrer">
                                <img src={twitter_logo} alt="img"/>
                            </a>
                        </div>
                        <div className="team-item">
                            <img className="dino-egg" src={dino_egg} alt="img"/>

                            <h2>Hashtag</h2>
                            <p>Co-Founder of Exploring Dino’s</p>
                            <a href="https://twitter.com/NFT_hashtag" target="_blank" rel="noreferrer">
                                <img src={twitter_logo} alt="img"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <p className="rights">Exploring Dino's 2022 © All Rights Reserved</p>
                    </div>
                </div>
                <img className="footer-bg" src={footer_bg} alt="img"/>
            </div>
        </footer>

      </div>
  )
}
export default Home;