/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import * as Constants from '../constants/config';

const backend_server = Constants.BACKEND_SERVER;

const ResetPassword = () => {
  const [setName] = useState('');
  const [setToken] = useState('');
  const [expire, setExpire] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [msg, setMsg] = useState('');
  const history = useNavigate();

  const ChangePassword = async (e) => {
      e.preventDefault();
      try {
          await axios.post(`${backend_server}/changepassword`, {
              password: password,
              confPassword: confPassword
          });
          history("/");
      } catch (error) {
          if (error.response) {
              setMsg(error.response.data.msg);
          }
      }
  }

  useEffect(() => {
    refreshToken();
    document.body.style.backgroundImage = "none";
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${backend_server}/token`);
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history("/");
      }
    }
  }

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date();
    if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get(`${backend_server}/token`);
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  return (
      
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <form onSubmit={ChangePassword} className="box">
              <p className="has-text-centered">{msg}</p>
              <div className="form-group">
                  <label>Password</label>
                    <input type="password" className="form-control" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="form-group">
                  <label>Confirm Password</label>
                  <input type="password" className="form-control" placeholder="******" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
              </div>
              <div className="form-group">
                  <button className="btn btn-default">Update Password</button>
              </div>
          </form>
          </div>
        </div>
    </div>
  )
}

export default ResetPassword