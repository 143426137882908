import { useEffect, useState } from 'react';
import '../App.scss';
import abi from '../contracts/abi';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
import * as Constants from '../constants/config';
import axios from 'axios';

const contractAddress = Constants.CONTRACT_ADDRESS; 
const networkChainId = 1;
const wrongChainErrorMessage = "You need to change network to Ethereum Mainnet";
const rpcUrls = "https://mainnet.infura.io/v3/";
const backend_server = Constants.BACKEND_SERVER;

const Mint = () => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [mintTotal, setMintTotal] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [whitelistTokenPrice, setWhitelistTokenPrice] = useState(0);

  const decrementCount = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const incrementCount = () => {
    setQuantity(quantity + 1);
  };

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const { chainId } = await provider.getNetwork();

    if (!ethereum) {
      toast.error('Make sure you have Metamask installed!', {id: "toast"})
      return;
    } else {
        toast.success("Wallet exists! We're ready to go!", {id: "toast"})
    }

    if(chainId !== networkChainId) {
      toast((t) => (
        <span>
          {wrongChainErrorMessage}
          <button onClick={onClickConnect} className="btn wallet-connect">
            Change
          </button>
        </span>
      ), {id: "change-net"});
      setCurrentAccount(null);
      
    } else {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
  
      if (accounts.length !== 0) {
        const account = accounts[0];
        //console.log("Found an authorized account: ", account);
        setCurrentAccount(account);
      } else {
        toast.error("No authorized account found");
      }
    }
  }

  const onClickConnect = async () => {
    const { ethereum } = window;
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x'+networkChainId}],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x'+networkChainId,
                chainName: 'ETH',
                rpcUrls: [rpcUrls],
              },
            ],
          });
          window.location.reload(false);
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  };

  const connectWalletHandler = async () => {
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const { chainId } = await provider.getNetwork();

    if (!ethereum) {
      toast.error('Please install Metamask!', {id: "toast"})
    }

    try {

      if(chainId !== networkChainId) {
        toast((t) => (
          <span>
            {wrongChainErrorMessage}
            <button onClick={onClickConnect} className="btn wallet-connect">
              Change
            </button>
          </span>
        ));
      } else {
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        setCurrentAccount(accounts[0]);
        window.location.reload(false);
      }
    } catch (err) {
      console.log(err)
    }
  }

  const mintToken = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, abi, provider);
    const mode = ((await contract.getSaleMode()).toNumber());
    if(mode === 1) {
      await whitelistMintTokens();
    } else if(mode === 2) {
      await mintNftHandler();
    } else {
      toast.error("Sale has not begun yet", {id: "toast"});    
    }
  }

  const whitelistMintTokens = async () => {
    try {
      const { ethereum } = window;
      const provider = new ethers.providers.Web3Provider(ethereum);
      const { chainId } = await provider.getNetwork();

      if (ethereum) {
        if(chainId !== networkChainId) {
          toast((t) => (
            <span>
              {wrongChainErrorMessage}            
              <button onClick={onClickConnect} className="btn wallet-connect">
                Change
              </button>
            </span>
          ), {id: "change"});
        } else {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
          let totalAmount = whitelistTokenPrice * quantity;
          let hexProof;
          const response = await axios.get(`${backend_server}/hex-proof`, {
              params: { address: currentAccount },
          })
          hexProof = response.data.proof;

          toast.loading("Initialize payment", {id: "toast"});
          let nftTxn = await nftContract.mintPreSaleTokens(quantity, hexProof, {value: ethers.utils.parseEther(totalAmount.toString())});

          toast.loading("Transaction is progress.\n please wait...", {duration: Infinity, id: "toast"});
          await nftTxn.wait();

          toast.success("NFT mint successful!", {duration: 4000, id: "toast"});
          const contract = new ethers.Contract(contractAddress, abi, provider);
          setMintTotal((await contract.totalSupply()).toNumber());
        }
      } else {
        //alert.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19), {id: "toast"})
    }
  }

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;
      const provider = new ethers.providers.Web3Provider(ethereum);
      const { chainId } = await provider.getNetwork();

      if (ethereum) {
        if(chainId !== networkChainId) {
          toast((t) => (
            <span>
              {wrongChainErrorMessage}            
              <button onClick={onClickConnect} className="btn wallet-connect">
                Change
              </button>
            </span>
          ), {id: "change"});
        } else {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
          let totalAmount = tokenPrice * quantity;

          toast.loading("Initialize payment", {id: "nft-p-payment"});
          let nftTxn = await nftContract.publicSaleMint(quantity, { value: ethers.utils.parseEther(totalAmount.toString()) });

          toast.loading("Transaction is progress.\n please wait...", {duration: Infinity, id: "toast"});
          await nftTxn.wait();

          toast.success("NFT mint successful!", {duration: 4000, id: "toast"});
          const contract = new ethers.Contract(contractAddress, abi, provider);
          setMintTotal((await contract.totalSupply()).toNumber());
        }
      } else {
        //alert.error("Ethereum object does not exist");
      }

    } catch (err) {
      toast.error(err.error.message.substr(19))
    }
  }

  const connectWalletButton = () => {
    return (
        <button onClick={connectWalletHandler} className='btn btn-mint box-shadow'>
          <span>Connect Wallet</span>
        </button>
    )
  }

  const mintNftButton = () => {
    return (
        <button onClick={mintToken} className='btn btn-mint box-shadow'>
          <span>Mint</span>
        </button>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
    getConfig();
    document.cookie = `referral_key=hello;max-age=604800`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getConfig = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      setMintTotal((await contract.totalSupply()).toNumber());
      setMaxSupply((await contract.MAX_SUPPLY()).toNumber());
      const tokenprice = (await contract.PRICE()).toString() / 1E18;
      setTokenPrice(tokenprice);
      const wlproce = (await contract.PRESALE_PRICE()).toString() / 1E18;
      setWhitelistTokenPrice(wlproce);
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <section id="mint" className="mint-container theme-color">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 text-left left">
                    <h2 className="title">Mint</h2>
                </div>
                <div className="col-sm-12 right text-center">
                  <div className="number-container">
                      <h3>{mintTotal}/{maxSupply}</h3>
                  </div>
                  <div className="counter-container">
                      <div className="counter">
                            <button className="btn minus box-shadow" onClick={decrementCount}>-</button>
                            <input
                                type="text"
                                className="box-shadow"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                            <button className="btn plus box-shadow" onClick={incrementCount}>+</button>
                      </div>
                      {currentAccount ? mintNftButton() : connectWalletButton()}
                  </div>
                </div>
            </div>
        </div>
    </section>
  )
}
export default Mint;