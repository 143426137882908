const Faq = () => {
  return (
    <section id="faq" className="faq-container">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="title-container">
                        <h1 className="title">FaQ<span className="small">s</span></h1>
                    </div>
                </div>
                <div className="col-sm-12 text-left">
                    <div className="panel-group" id="faqCollapse" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        When is the mint? <span className="glyphicon glyphicon-menu-up" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    <p>It’s on: TBA</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingTwo">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        What is the supply of Exploring Dino’s? <span className="glyphicon glyphicon-menu-up" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                <div className="panel-body">
                                    <p>5555 NFT’s</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingThree">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        What are the maximum mints per wallet?<span className="glyphicon glyphicon-menu-up" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                <div className="panel-body">
                                    <p>3 mints per wallet is allowed</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="heading4">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                        Is there a Pre-Sale?<span className="glyphicon glyphicon-menu-up" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading4">
                                <div className="panel-body">
                                    <p>Yes, we have Whitelist spots for the Exploring Dino’s</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="heading5">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faqCollapse" href="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                        Will Exploring Dino’s be revealed after the mint?<span className="glyphicon glyphicon-menu-up" aria-hidden="true"></span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading5">
                                <div className="panel-body">
                                    <p>No, not directly. That’s on the date: TBA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )}
export default Faq;